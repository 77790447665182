import React from "react";
import { Modal, Button } from "react-bootstrap";
import logo from "../Images/asi_logo.png";

interface Props {
  clientName: string;
  projectName: string;
  setClientName: (arg0: string) => void;
  setProjectName: (arg0: string) => void;
  setStep: (arg0: string) => void;
}

const WelcomeModal = ({
  clientName,
  projectName,
  setClientName,
  setProjectName,
  setStep,
}: Props) => {
  return (
    <Modal centered show={true} backdrop={"static"} keyboard={false}>
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Modal.Title>
          <img src={logo} style={{ width: "200px" }} />
          <br />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ textAlign: "center" }}>
          <h3 style={{ marginBottom: "10px" }}>
            Welcome to Aerial Services, Inc.
          </h3>
          <p>Let us price your project today!</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => setStep("aoi")}
        >
          Get Started
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WelcomeModal;
