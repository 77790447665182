import React from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";

interface Props {
  setStep: (arg0: string) => void;
}

const AOIErrorModal = ({setStep}) => {
  return (
    <Modal
      centered
      show={true}
      animation={false}
      backdrop={"static"}
      keyboard={false}
    >
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Modal.Title>AOI Error</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: "center" }}>
        <p>Please modify your AOI to not exceed 300,000 square miles.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ float: "left" }}
          variant="primary"
          onClick={() => setStep("draw")}
        >
          Modify AOI
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AOIErrorModal;
