import React, { useEffect } from "react";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "bootstrap/dist/css/bootstrap.min.css";

import "./ClientEstimate.css";
import logo from "../Images/asi_logo.png";
import { Button } from "react-bootstrap";

const ClientEstimate = ({ record }) => {
  console.log({ record });

  useEffect(() => {
    document.title = `ASI Quote - ${new Date().toISOString().slice(0,10)}`;
  }, []);

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <div>
      <Navbar expand="lg" style={{ background: "rgb(35 51 68)" }}>
        <Container>
          <Navbar.Brand style={{ color: "white", fontWeight: "bold" }}>
            Project Quote
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Button variant="secondary" onClick={() => window.print()}>
              Print
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container id="content-container" style={{ padding: "1em" }}>
        <Row style={{ padding: "1em 0 3em" }}>
          <Col>
            <img src={logo} style={{ width: "250px" }} />
          </Col>
          <Col>
            <div
              style={{
                width: "300px",
                float: "right",
                textAlign: "right",
                borderBottom: "none",
              }}
            >
              <h5>Aerial Services, Inc</h5>
              <h6 style={{ marginBottom: "0.25rem" }}>6315 Chancellor Dr.</h6>
              <h6 style={{ marginBottom: "0.25rem" }}>Cedar Falls, IA 50613</h6>
              <h6 style={{ marginBottom: "0.25rem" }}>(319) 277-0436</h6>
            </div>
          </Col>
        </Row>

        <h4>General</h4>
        <hr />
        <Table bordered size="sm" style={{ width: "500px" }}>
          <tbody>
            <tr>
              <td>
                <b>Client Name</b>
              </td>
              <td>{record.client_name}</td>
            </tr>
            <tr>
              <td>
                <b>Project Name</b>
              </td>
              <td>{record.project_name}</td>
            </tr>
          </tbody>
        </Table>

        <h4>Schedule</h4>
        <hr />
        <Table bordered size="sm" style={{ width: "500px" }}>
          <tbody>
            <tr>
              <td>
                <b>Project Timeline Requirements</b>
              </td>
            </tr>
            <tr>
              <td>{record.sched_restrict_label}</td>
            </tr>
          </tbody>
        </Table>

        <h4>Deliverables</h4>
        <hr />
        {record.lidar_co_ca_cl_enabled && (
          <>
            <h5>Lidar</h5>
            <Table bordered size="sm" style={{ width: "500px" }}>
              <tbody>
                <tr>
                  <td>
                    <b>PPSM</b>
                  </td>
                  <td>{record.lidar_co_ca_cl_label}</td>
                </tr>
                <tr>
                  <td>
                    <b>Accuracy</b>
                  </td>
                  <td>{record.lidar_co_ca_cl_accuracy_label}</td>
                </tr>
              </tbody>
            </Table>

            {record.lad_enabled && (
              <>
                <h5>Additional Lidar Deliverables</h5>
                <Table bordered size="sm" style={{ width: "500px" }}>
                  <tbody>
                    {record.lad_ground_classified_dem && (
                      <tr>
                        <td>Ground Classified DEM</td>
                      </tr>
                    )}
                    {record.lad_plan_ref_contours && (
                      <tr>
                        <td>Reference Contours</td>
                      </tr>
                    )}
                    {record.lad_plan_map_offshore && (
                      <tr>
                        <td>Planimetric Mapping (offshore)</td>
                      </tr>
                    )}
                    {record.lad_plan_map_onshore && (
                      <tr>
                        <td>Planimetric Mapping (onshore)</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </>
            )}
          </>
        )}

        {record.image_co_or_enabled && (
          <>
            <h5>Imagery Collection, Orthorectified</h5>
            <Table bordered size="sm" style={{ width: "500px" }}>
              <tbody>
                <tr>
                  <td>
                    <b>GSD</b>
                  </td>
                  <td>{record.image_co_or_label}</td>
                </tr>
                <tr>
                  <td>
                    <b>Accuracy</b>
                  </td>
                  <td>{record.image_co_or_accuracy_label}</td>
                </tr>
                <tr>
                  <td>
                    <b>Required Imagery Grade</b>
                  </td>
                  <td>{record.image_co_or_grade}</td>
                </tr>
                <tr>
                  <td>
                    <b>Required Sun Angle</b>
                  </td>
                  <td>{record.image_co_or_sun_angle}</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}

        {record.other_enabled && (
          <>
            <h4>Requested Other Options</h4>
            <hr />

            <Table bordered size="sm" style={{ width: "500px" }}>
              <tbody>
                {record.other_quote_valid_30_days && (
                  <tr>
                    <td colSpan={2}>Quote Valid for 30 Days</td>
                  </tr>
                )}
                {record.other_quote_valid_60_days && (
                  <tr>
                    <td colSpan={2}>Quote Valid for 60 Days</td>
                  </tr>
                )}
                {record.other_quote_valid_180_days && (
                  <tr>
                    <td colSpan={2}>Quote Valid for 180 Days</td>
                  </tr>
                )}
                {record.other_control_statistics_report && (
                  <tr>
                    <td colSpan={2}>Control Statistics Report</td>
                  </tr>
                )}
                {record.other_processing_report && (
                  <tr>
                    <td colSpan={2}>
                      Processing Report (Per Type - Imagery & LiDAR)
                    </td>
                  </tr>
                )}

                {record.other_need_flight_plan && (
                  <tr>
                    <td colSpan={2}>Need Flight Plan/Layout finalized now</td>
                  </tr>
                )}
                {record.other_special_airspace && (
                  <tr>
                    <td colSpan={2}>Special Airspace</td>
                  </tr>
                )}
                {record.other_control_survey && (
                  <tr>
                    <td>Control Survey</td>
                    <td>{record.other_control_survey_points} Points</td>
                  </tr>
                )}
                {record.other_mountainous_terrain && (
                  <tr>
                    <td colSpan={2}>Mountainous Terrain</td>
                  </tr>
                )}

                {record.other_payment_card_paypal_venmo && (
                  <tr>
                    <td colSpan={2}>Payment via card, Paypal, Venmo, etc.</td>
                  </tr>
                )}
                {record.other_first_airborne_lidar && (
                  <tr>
                    <td colSpan={2}>First Airborne LiDAR project</td>
                  </tr>
                )}
                {record.other_work_limitations && (
                  <tr>
                    <td>Work Limitations</td>
                    <td>{record.other_work_limitations}</td>
                  </tr>
                )}
                {record.other_only_client && (
                  <tr>
                    <td colSpan={2}>
                      This data is only for use by Client, no release
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </>
        )}

        <h5>Price</h5>
        <Table bordered size="sm" style={{ width: "500px" }}>
          <tbody>
            <tr>
              <td>
                <b>Total</b>
              </td>
              <td>{currencyFormatter.format(record.total)}</td>
            </tr>
          </tbody>
        </Table>
        {record.airspaces.length > 0 && (
          <Table
            bordered
            size="sm"
            style={{ width: "500px" }}
            className="no-break"
          >
            <tbody>
              <tr>
                <td style={{ padding: "0.5em" }}>
                  <b>
                    This project may be subject to special coordination with the
                    following airspaces:
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    {record.airspaces.map((airspace) => (
                      <li>{airspace}</li>
                    ))}
                  </ul>
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        <div className="page-break"></div>
        <h4>Terms and Conditions</h4>
        <hr />
        <p>
          1. Warranty. All work performed under this Agreement shall follow the
          map accuracy standards indicated on the attached bidding sheet for the
          horizontal and/or vertical products, scales and ground sample
          distances specified. Standard use of photogrammetric compiled maps
          dictates that the maps be field checked as to their accuracies prior
          to use. Methods for assessing accuracy are outlined in the ASPRS
          Positional Accuracy Standards for Digital Geospatial Data, Edition 2,
          Version 1.0, 2023. Any errors or omissions of data within the area
          mapped should be reported to Aerial Services, Inc. (“ASI”). If ASI
          receives no notification of errors or omissions within 60 days of
          receipt of the mapping, the mapping shall be considered as acceptable
          and approved by the client. THE FOREGOING WARRANTY IS EXCLUSIVE AND IN
          LIEU OF ALL OTHER WARRANTIES, WHETHER WRITTEN, ORAL, OR IMPLIED
          (INCLUDING ANY WARRANTY OF MERCHANTABILITY OR FITNESS FOR PURPOSE.)
        </p>
        <p>
          2. Contingencies. ASI shall not be deemed to have defaulted or failed
          to perform this agreement if ASI’s inability to perform or default
          shall have been caused by an event or events beyond the control and
          without the fault of ASI, including, but not limited to federal, state
          or municipal action or regulation, acts of God or a public enemy,
          embargos, strikes, labor disputes, fire, flood, inclement weather,
          explosions, vandalism, civil riots or commotions, or the inability to
          procure necessary raw materials, supplies or equipment. The existence
          of such causes of delay shall justify the suspension of all work
          performed under this Agreement and shall extend the time of
          performance on ASI’s part to the extent necessary to enable it to make
          delivery in the exercise of reasonable diligence after the causes of
          delay have been removed. However, in the event the existence of any
          such causes of delay make the performance of this Agreement
          impracticable, ASI may, without liability, be excused from the
          performance of this Agreement.
        </p>
        <p>
          3. Limitation of Damages. In the event it is determined that ASI has
          breached this Agreement, the parties agree that damages shall be the
          difference at the time and place of acceptance between the value of
          the services and photographic products and/or mapping data accepted
          and the value they would have had if they had been as warranted. No
          claim shall be made for proximate damages of a different amount, nor
          may any recovery be had for incidental or consequential damages.
        </p>

        <p>
          4. Client Property. Aerial Services, Inc. takes every reasonable
          precaution to protect client documents and property from damage or
          destruction. However, in the unlikely event that such damage or
          destruction would occur, Aerial Services is not responsible for the
          damages. For this reason, we require that our clients maintain
          adequate insurance to protect their property while it is in the
          possession of ASI. We may request proof of such insurance coverage.
        </p>
        <p>
          5. Notices. All notices or other communications hereunder shall be
          given in writing and shall be deemed to be, if duly given or delivered
          or mailed, first class postage prepaid, to the addresses set forth in
          this Agreement, or to such other address as directed in writing.
        </p>
        <p>
          6. Governing Law. The validity, interpretation, performance, and
          enforcement of this Agreement shall be governed by the laws of Iowa.
          Each of the parties consents to the jurisdiction of the federal and
          state courts in Iowa in all matters relating to this Agreement. The
          prevailing party in any action to enforce this Agreement shall be
          entitled to reasonable attorney fees and costs.
        </p>
        <p>
          7. Severability. If any term or provision of this Agreement or the
          application thereof to any circumstance shall, in any jurisdiction and
          to any extent, be invalid or unenforceable, such term or provision
          shall be ineffective as to such jurisdiction to the extent of such
          invalidity or unenforceability without invalidating or rendering
          unenforceable such term or provision in any other jurisdiction, the
          remaining terms and provisions of this Agreement or the application of
          such terms and provisions to circumstances other than those as to
          which it is held invalid or enforceable.
        </p>
        <p>
          8. Amendment and Waiver. Neither this Agreement nor any provision or
          provisions herein may be amended or waived except by a written
          amendment or new agreement executed by the parties.
        </p>
        <p>
          9. Entire Agreement. In entering into and closing this Agreement, no
          party has relied or shall rely upon any promises, representations and
          warranties not expressed herein, and this Agreement expresses their
          entire agreement on the subject matter.
        </p>
        <p>
          10. Electronic filing. It is our policy to keep the hard copy of this
          contract for 60 days. Thereafter, we will scan and store the contract
          electronically, and the hard copy of your file and all its contents
          will be permanently destroyed without further notice to you. You may
          retrieve your file, including the hard copy of the contract, and all
          its contents at any time during that 60-day period. If you desire to
          do so, please contact our office.{" "}
        </p>

        <p>
          Note 1. A charge of $150 per hour will be assessed for each hour of
          time necessary to resolve any errors or other problems associated with
          the field control supplied by the Client or its representative.
        </p>
        <p>Note 2. All services will be billed as work progresses.</p>
        <p>
          Note 3. Our terms are as follows: Net 30 days - 1.5% per month on all
          accounts over 30 days, $3 minimum, unless otherwise specified by the
          Client at time of contract.
        </p>
        <p>
          Note 4. All quoted pricing is valid for 60 days from issuance of
          quote. If the authorization or notice to proceed is not received
          within 90 days of the date of issuance, prices set forth are subject
          to change.
        </p>

        <div className="no-break">
          <h4>Acceptance</h4>
          <hr />
          <Row>
            <Col>
              <h6>Aerial Services, Inc.</h6>
              <Table bordered size="sm">
                <tbody>
                  <tr>
                    <td style={{ width: "30%" }}>
                      <b>Signature</b>
                    </td>
                    <td style={{ width: "70%" }}></td>
                  </tr>
                  <tr>
                    <td>
                      <b>Name</b>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <b>Title</b>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <b>Date</b>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col>
              <h6>{record.client_name}</h6>
              <Table bordered size="sm">
                <tbody>
                  <tr>
                    <td style={{ width: "30%" }}>
                      <b>Signature</b>
                    </td>
                    <td style={{ width: "70%" }}></td>
                  </tr>
                  <tr>
                    <td>
                      <b>Name</b>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <b>Title</b>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <b>Date</b>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default ClientEstimate;
