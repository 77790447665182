import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Form,
  InputGroup,
} from "react-bootstrap";
import * as tj from "@mapbox/togeojson";
import rewind from "@mapbox/geojson-rewind";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useMask } from "@react-input/mask";

import { FormParams } from "../Types/Types";

interface Props {
  update: (arg0: string, arg1: boolean | string | number) => void;
  formObj: FormParams;
  submit: () => void;
  submitted: boolean;
  setStep: (arg1: string) => void;
}

const AdditionalInfoModal = ({
  update,
  formObj,
  submit,
  submitted,
  setStep,
}: Props) => {
  const inputRef = useMask({
    mask: "+_ (___) ___-____ ext. ______",
    replacement: { _: /\d/ },
  });

  const [phoneNumber, setPhoneNumber] = useState(formObj.phone_no);
  const [phoneNumberValid, setPhoneNumberValidate] = useState(false);
  const [email, setEmail] = useState(formObj.email);
  const [emailValid, setEmailValid] = useState(false);

  console.log({ phoneNumberValid, emailValid });

  const handleChange = (e) => {
    update(e.target.name, e.target.value);
  };

  useEffect(() => {
    // @ts-ignore
    if (isValidPhoneNumber(phoneNumber, "US")) {
      update("phone_no", phoneNumber);
      setPhoneNumberValidate(true);
    } else {
      update("phone_no", "");
      setPhoneNumberValidate(false);
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
        email
      )
    ) {
      update("email", email);
      setEmailValid(true);
    } else {
      update("email", "");
      setEmailValid(false);
    }
  }, [email]);

  const checkFormState = () => {
    return (
      !formObj.client_name ||
      !formObj.project_name ||
      !phoneNumberValid ||
      !emailValid
    );
  };

  return (
    <Modal
      centered
      show={true}
      animation={false}
      backdrop={"static"}
      keyboard={false}
      size="xl"
    >
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Modal.Title>Quote Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ width: "80%", margin: "0 auto" }}>
          <p style={{ textAlign: "center" }}>
            Please submit your contact information below then your price will be
            instantly provided. No more waiting!
          </p>

          <Container>
            <Row style={{ margin: "0.5em 0" }}>
              <Col>
                <Form.Label
                  htmlFor="sched_restrict_label"
                  style={{ marginTop: "0.5em" }}
                >
                  Project Schedule
                </Form.Label>
                <Form.Select
                  id="sched_restrict_label"
                  name="sched_restrict_label"
                  onChange={handleChange}
                  defaultValue={formObj.sched_restrict_label}
                >
                  <option value="12 month flexibility, absolute best price">
                    12 month flexibility, absolute best price
                  </option>
                  <option value="4-8+ week flexibility, flexible delivery">
                    4-8+ week flexibility, flexible delivery
                  </option>
                  <option value="2-4 week flexibility, normal delivery">
                    2-4 week flexibility, normal delivery
                  </option>
                  <option value="Collect within 2 weeks, normal delivery">
                    Collect within 2 weeks, normal delivery
                  </option>
                  <option value="Collect within 3 days, expedited delivery">
                    Collect within 3 days, expedited delivery
                  </option>
                  <option value="Collect & deliver as soon as possible">
                    Collect & deliver as soon as possible
                  </option>
                </Form.Select>

                <Form.Label
                  htmlFor="quote_validity"
                  style={{ marginTop: "0.5em" }}
                >
                  Quote Validity
                </Form.Label>
                <Form.Select
                  id="quote_validity"
                  onChange={(e) => {
                    // delete the quote validity properties
                    // => these were checkboxes and didn't want to update the db
                    delete formObj.other_quote_valid_30_days;
                    delete formObj.other_quote_valid_60_days;
                    delete formObj.other_quote_valid_180_days;

                    update(e.target.value, true);
                  }}
                  defaultValue={"other_quote_valid_30_days"}
                >
                  <option value="other_quote_valid_30_days">
                    Quote Valid for 30 Days
                  </option>
                  <option value="other_quote_valid_60_days">
                    Quote Valid for 60 Days
                  </option>
                  <option value="other_quote_valid_180_days">
                    Quote Valid for 180 Days
                  </option>
                </Form.Select>

                <Form.Label
                  htmlFor="limit_subcontracting"
                  style={{ marginTop: "0.5em" }}
                >
                  Work Limitations
                </Form.Label>
                <Form.Select
                  id="limit_subcontracting"
                  defaultValue={formObj.other_work_limitations}
                  onChange={(e) => {
                    update("other_work_limitations", e.target.value);
                  }}
                >
                  <option value=""></option>
                  <option value="ASI must perform this work">
                    ASI must perform this work
                  </option>
                  <option value="ASI may outsource work with approval">
                    ASI may outsource work with approval
                  </option>
                  <option value="Get this job done as cost effectively as possible">
                    Get this job done as cost effectively as possible
                  </option>
                </Form.Select>

                <Form.Label style={{ marginTop: "0.5em" }}>Payment</Form.Label>
                <Form.Check
                  type={"checkbox"}
                  defaultChecked={formObj.other_payment_card_paypal_venmo}
                  id="other_payment_card_paypal_venmo"
                  name="other_payment_card_paypal_venmo"
                  label={"Payment via card, Paypal, Venmo, etc."}
                  onChange={(e) =>
                    update(e.target.name, e.target.checked ? true : false)
                  }
                />
              </Col>

              <Col>
                <Form.Group style={{ marginTop: "0.5em" }}>
                  <Form.Label htmlFor="client_name">Client Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={formObj.client_name}
                    id="client_name"
                    onChange={(e) => update("client_name", e.target.value)}
                    autoComplete="off"
                    isValid={!!formObj.client_name}
                    isInvalid={!formObj.client_name}
                  />
                </Form.Group>
                <Form.Group style={{ marginTop: "0.5em" }}>
                  <Form.Label htmlFor="project_name">Project Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="project_name"
                    value={formObj.project_name}
                    onChange={(e) => update("project_name", e.target.value)}
                    autoComplete="off"
                    isValid={!!formObj.project_name}
                    isInvalid={!formObj.project_name}
                  />
                </Form.Group>
                <Form.Group style={{ marginTop: "0.5em" }}>
                  <Form.Label htmlFor="phone_no">Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    ref={inputRef}
                    id="phone_no"
                    value={phoneNumber}
                    placeholder={"+1 (123) 456-7890 ext. 123456"}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    autoComplete="off"
                    isValid={phoneNumberValid}
                    isInvalid={!phoneNumberValid}
                  />
                  <Form.Control.Feedback type="invalid">
                    Phone Number must include the Country Code and can have an
                    extension of up to 6 digits
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group style={{ marginTop: "0.5em" }}>
                  <Form.Label htmlFor="email">Email</Form.Label>
                  <Form.Control
                    type="text"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={"user@example.com"}
                    autoComplete="off"
                    isValid={emailValid}
                    isInvalid={!emailValid}
                  />
                  <Form.Control.Feedback type="invalid">
                    Email must contain an '@' symbol and valid domain extension
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ float: "left" }}
          variant="secondary"
          onClick={() => setStep("project_details")}
        >
          View Project Details
        </Button>
        <Button
          variant="primary"
          onClick={() => submit()}
          disabled={checkFormState() || submitted}
        >
          {submitted ? "Calculating" : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AdditionalInfoModal;
