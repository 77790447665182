import React, { useRef } from "react";
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Form,
  Accordion,
} from "react-bootstrap";
import { FormParams } from "../Types/Types";

interface Response {
  token: string;
  message: string;
  pass: boolean;
  total: number;
}

interface Props {
  formObj: FormParams;
  response: Response;
  setStep: (arg0: string) => void;
  host: string;
  airSpaceResult: string[];
}

const OutputModal = ({
  response,
  setStep,
  host,
  formObj,
  airSpaceResult,
}: Props) => {
  // console.log({ response, setStep, host, formObj });

  const renderEmail = () => {
    let subject = `Project: ${formObj.project_name}`;
    let body = `
      <a href="${host}/estimate/${response.token}">View Estiamte Report</a>
    `;

    window.open(
      "mailto:sales@aerialservicesinc.com?body=" +
        encodeURIComponent(body) +
        "&subject=" +
        encodeURIComponent(subject)
    );
  };

  return (
    <Modal
      centered
      show={true}
      animation={false}
      backdrop={"static"}
      keyboard={false}
      size="lg"
    >
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Modal.Title>
          Estimated Price{" "}
          {airSpaceResult.length > 0 ? "(Pending Airspace Approval)" : ""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: "left" }}>
        <Container>
          <h2 style={{ textAlign: "center", margin: ".5em 0 1em" }}>
            $
            {response.total.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
          </h2>

          <Container style={{ maxWidth: "95%" }}>
            <Row style={{ margin: "0.5em 0" }}>
              <Col>
                <Button
                  variant="secondary"
                  onClick={() => setStep("draw")}
                  style={{ width: "100%" }}
                >
                  Adjust AOI
                </Button>
              </Col>
              <Col>
                <Button
                  variant="secondary"
                  onClick={() => setStep("additional_info")}
                  style={{ width: "100%" }}
                >
                  Adjust Quote Details
                </Button>
              </Col>
              <Col>
                <Button
                  variant="secondary"
                  onClick={() => setStep("project_details")}
                  style={{ width: "100%" }}
                >
                  Adjust Project Details
                </Button>
              </Col>
            </Row>
            {airSpaceResult.length > 0 && (
              <>
                <hr />
                <Row style={{ margin: "0.5em 0" }}>
                  <Col>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          This project may be subject to special coordination
                          with the following airspaces
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            {airSpaceResult.map((record) => (
                              <li>{record}</li>
                            ))}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                </Row>
              </>
            )}
            <hr />
            <Row style={{ margin: "0.5em 0" }}>
              <Col>
                <Form.Label htmlFor="sales-contact">
                  Contact us to begin this Project
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    id="sales-contact"
                    value="sales@aerialservicesinc.com"
                    readOnly
                  />
                  <Button variant="primary" onClick={() => renderEmail()}>
                    Launch Email App
                  </Button>
                </InputGroup>
              </Col>
            </Row>

            <Row style={{ margin: "0.5em 0 2em" }}>
              <Col>
                <Button
                  variant="primary"
                  href={`/estimate/${response.token}`}
                  target="_blank"
                  style={{ width: "100%" }}
                >
                  Print Quote for Signature
                </Button>
              </Col>
            </Row>
          </Container>
        </Container>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={() => setResponse(null)}>
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default OutputModal;
