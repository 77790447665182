import React, { useEffect, useState } from "react";
import { Button, ListGroup, Modal, Spinner } from "react-bootstrap";

interface Props {
  features: any;
  serviceAreaResult: boolean;
  airSpaceResult: string[];
  setAirspaceResult: (arg0: string[]) => void;
  setSserviceAreaResult: (arg0: boolean) => void;
  serviceArea: boolean;
  airspace: boolean;
  setSserviceArea: (arg0: boolean) => void;
  setAirspace: (arg0: boolean) => void;
  resetServiceArea: () => void;
}

const SpatialQueryModal = ({
  features,
  serviceAreaResult,
  airSpaceResult,
  setAirspaceResult,
  setSserviceAreaResult,
  serviceArea,
  airspace,
  setSserviceArea,
  setAirspace,
  resetServiceArea,
}: Props) => {
  useEffect(() => {
    queryFeatureLayers();
  }, []);

  const queryFeatureLayers = async () => {
    console.log("queryFeatureLayers", features);

    const geometry = convertFeatures(features);

    console.warn(geometry);

    // const geometry_obj = {
    //   rings: [],
    //   spatialReference: { wkid: 4326 },
    // };

    // geometry.features.forEach((record) => {
    //   geometry_obj.rings.push(record.geometry.rings[0]);
    // });

    try {
      const service_response = await fetch(
        "https://services2.arcgis.com/PMIDKf0WPRJXo4GQ/ArcGIS/rest/services/US_BOUNDARY_BUFFER/FeatureServer/0/query",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
          },
          body: new URLSearchParams({
            f: "json",
            where: "1=1",
            geometry: JSON.stringify(geometry),
            geometryType: "esriGeometryPolygon",
            spatialRel: "esriSpatialRelIntersects",
            returnCountOnly: "true",
          }),
        }
      );

      const service_result = await service_response.json();
      setSserviceAreaResult(true);

      if (service_result.count > 0) {
        setSserviceArea(true);
      } else {
        setSserviceArea(false);
      }

      const airspace_response = await fetch(
        "https://services2.arcgis.com/PMIDKf0WPRJXo4GQ/ArcGIS/rest/services/CoordinationAirspace_Simplified/FeatureServer/0/query",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
          },
          body: new URLSearchParams({
            f: "json",
            where: "1=1",
            geometry: JSON.stringify(geometry),
            geometryType: "esriGeometryPolygon",
            spatialRel: "esriSpatialRelIntersects",
            returnGeometry: "false",
            outFields: "NAME",
          }),
        }
      );

      const airspace_result = await airspace_response.json();
      setAirspace(true);

      console.log({
        airspace_result,
        extract: airspace_result.features.map(
          (record) => record.attributes.NAME
        ),
        check: airspace_result.features.length > 0,
      });

      if (airspace_result.features.length > 0) {
        setAirspaceResult(
          airspace_result.features.map((record) => record.attributes.NAME)
        );
      } else {
        setAirspaceResult([]);
      }

      // return result.features;
    } catch (error) {
      console.error("POST request failed:", error);
      throw error;
    }
  };

  const convertFeatures = (geojson) => {
    // if (!geojson.features) {
    //   throw new Error("Invalid GeoJSON: missing features array");
    // }

    return {
      rings: geojson.geometry.coordinates.map((ring) =>
        ring.map((point) => [point[0], point[1]])
      ),
      spatialReference: {
        wkid: 4326, // WGS84
      },
    };

    // return {
    //   features: geojson.features.map((feature) => ({
    //     geometry: {
    //       type: "polygon",
    //       rings: feature.geometry.coordinates.map((ring) =>
    //         ring.map((point) => [point[0], point[1]])
    //       ),
    //       spatialReference: {
    //         wkid: 4326, // WGS84
    //       },
    //     },
    //     attributes: feature.properties || {},
    //     id: feature.id,
    //   })),
    //   spatialReference: {
    //     wkid: 4326, // WGS84
    //   },
    // };
  };

  const renderServiceIcon = () => {
    if (!serviceAreaResult) {
      return renderLoading();
    } else {
      if (serviceArea === true) {
        return <i className="bi bi-check-lg"></i>;
      } else {
        return <i className="bi bi-x-lg"></i>;
      }
    }
  };

  const renderAirSpaceIcon = () => {
    if (!airspace) {
      return renderLoading();
    } else {
      return <i className="bi bi-check-lg"></i>;
    }
  };

  const renderLoading = () => {
    return (
      <Spinner
        animation="border"
        role="status"
        size="sm"
        style={{ marginRight: "0.5em" }}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  };

  console.log({ airSpaceResult, airspace, serviceAreaResult, serviceArea });

  if (serviceAreaResult && !serviceArea) {
    return (
      <Modal centered show={true} backdrop={"static"} keyboard={false}>
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Modal.Title>Error Validating Service Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            At this time ASI provides service to the contiguous 48 United
            States, Hawaii, and Puerto Rico. Please update your AOI to overlap
            these regions.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => resetServiceArea()}>
            Update AOI
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal centered show={true} backdrop={"static"} keyboard={false}>
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Modal.Title>Performing Spatial Validations</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          <ListGroup.Item>
            {renderServiceIcon()} Validating Service Areas
          </ListGroup.Item>
          <ListGroup.Item>
            {renderAirSpaceIcon()} Validating Airspace
          </ListGroup.Item>
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};

export default SpatialQueryModal;
